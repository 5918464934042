/* body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #fcfcfc;
} */

p {
  color: #b3b3b3;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

a,
a:hover {
  text-decoration: none !important;
}

h2 {
  font-size: 20px;
}

body {
  position: relative;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212837;
  text-align: left;
  /*background: #c9d2e3;*/
  /* background: #e9f0ff; */
  background: rgba(0, 0, 0, 0.05);
  min-height: 100vh;
}

button {
  cursor: pointer;
  border: 0;
}

a {
  text-decoration: none;
}


.bg-info {
  background-color: #17a2b8 !important;
  color: #fff !important;
}

.text-primary {
  color: #003768 !important;
}

.text-primary .active {
  background-color: #003768 !important;
}

.btn-primary {
  background-color: #003768 !important;
  --bs-btn-border-color: #003768 !important;
}

.dropdown-item:hover {
  background-color: #003768 !important;
  color: #fff !important;
}

.form-control-sm {
  font-size: .75rem !important;
}

.form-label {
  font-size: .825rem !important;
  margin-bottom: 0 !important;
}

.form-select-sm {
  font-size: .75rem !important;
}

.table-sm td,
.table-sm th {
  padding: 0.1rem;
}

.table thead th {
  vertical-align: bottom !important;
  border-bottom: 2px solid #dae0ec !important;
}

th {
  font-weight: bold !important;
}

.table td,
.table th {
  padding: 0.1rem;
  vertical-align: top;
  border-top: 1px solid #dae0ec;
}

.stack-0 {
  z-index: 1039;
  display: block;
}

.stack-b-0 {
  z-index: 1040;
  display: block;
}

.stack-1 {
  z-index: 1049;
  display: block;
}

.stack-b-1 {
  z-index: 1050;
  display: block;
}

.stack-2 {
  z-index: 1059;
  display: block;
}

.stack-b-2 {
  z-index: 1060;
  display: block;
}

/* body:before {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}

body.show-sidebar:before {
  opacity: 1;
  visibility: visible;
} */

.site-section {
  padding: 7rem 18rem 0;
}

aside,
main {
  height: 100vh;
  min-height: 580px;
}

aside {
  width: 275px;
  left: 0;
  z-index: 1001;
  position: fixed;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  background-color: #fff;
  -webkit-transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1), 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
}

.show-sidebar aside {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

aside .toggle {
  padding-left: 30px;
  padding-top: 30px;
  position: absolute;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.show-sidebar aside .toggle .burger:before,
.show-sidebar aside .toggle .burger span,
.show-sidebar aside .toggle .burger:after {
  background: #000;
}

.show-sidebar aside {
  -webkit-box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.1);
}

aside .side-inner {
  padding: 30px 0;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

aside .side-inner .profile {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #efefef;
}

aside .side-inner .profile img {
  width: 80px;
  margin: 0 auto 20px auto;
  border-radius: 50%;
}

aside .side-inner .profile .name {
  font-size: 18px;
  margin-bottom: 0;
}

aside .side-inner .profile .country {
  font-size: 14px;
  color: #cfcfcf;
}

aside .side-inner .counter {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #efefef;
  text-align: center;
}

aside .side-inner .counter div .number {
  display: block;
  font-size: 20px;
  color: #000;
}

aside .side-inner .counter div .number-label {
  color: #cfcfcf;
}

aside .side-inner .nav-menu ul,
aside .side-inner .nav-menu ul li {
  padding: 0;
  margin: 0px;
  list-style: none;
}

aside .side-inner .nav-menu ul li a {
  display: block;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8b;
  position: relative;
  -webkit-transition: .3s padding-left ease;
  -o-transition: .3s padding-left ease;
  transition: .3s padding-left ease;
}

aside .side-inner .nav-menu ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0px;
  background-color: #ff7315;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s opacity ease, .3s visibility ease, .3s width ease;
  -o-transition: .3s opacity ease, .3s visibility ease, .3s width ease;
  transition: .3s opacity ease, .3s visibility ease, .3s width ease;
}

aside .side-inner .nav-menu ul li a:active,
aside .side-inner .nav-menu ul li a:focus,
aside .side-inner .nav-menu ul li a:hover {
  outline: none;
}

aside .side-inner .nav-menu ul li a:hover {
  background: #fcfcfc;
  color: #000;
}

aside .side-inner .nav-menu ul li a:hover:before {
  width: 4px;
  opacity: 1;
  visibility: visible;
}

aside .side-inner .nav-menu ul li.active a {
  background: #fcfcfc;
  color: #000;
}

aside .side-inner .nav-menu ul li.active a:before {
  opacity: 1;
  visibility: visible;
  width: 4px;
}

aside .side-inner .nav-menu ul li .collapsible {
  position: relative;
}

aside .side-inner .nav-menu ul li .collapsible:after {
  content: "\e315";
  font-size: 18px;
  font-family: 'icomoon';
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-transition: .3s transform ease;
  -o-transition: .3s transform ease;
  transition: .3s transform ease;
}

aside .side-inner .nav-menu ul li .collapsible[aria-expanded="true"] {
  background: #fcfcfc;
  color: #000;
}

aside .side-inner .nav-menu ul li .collapsible[aria-expanded="true"]:before {
  opacity: 1;
  visibility: visible;
  width: 4px;
}

aside .side-inner .nav-menu ul li .collapsible[aria-expanded="true"]:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

main {
  width: calc(100%);
  -webkit-transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1), 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
}

/* .show-sidebar main {
  -webkit-transform: translateX(300px);
  -ms-transform: translateX(300px);
  transform: translateX(300px);
} */

main .post-entry {
  margin-bottom: 30px;
}

main .post-entry .custom-thumbnail {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  margin-right: 30px;
}

main .post-content h3 {
  font-size: 18px;
}

main .post-content .post-meta {
  font-size: 15px;
  color: #ccc;
}

@media only screen and (max-width: 1450px) {
  aside {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* Burger */
.burger {
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
  z-index: 99;
  float: right;
}

.burger:before,
.burger span,
.burger:after {
  width: 100%;
  height: 2px;
  display: block;
  background: #000;
  border-radius: 2px;
  position: absolute;
  opacity: 1;
}

.burger:before,
.burger:after {
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
}

.burger:before {
  top: 4px;
}

.burger span {
  top: 15px;
}

.burger:after {
  top: 26px;
}

/* Hover */
.burger:hover:before {
  top: 7px;
}

.burger:hover:after {
  top: 23px;
}

/* Click */
.burger.active span {
  opacity: 0;
}

.burger.active:before,
.burger.active:after {
  top: 40%;
}

.burger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
  /*for IE*/
}

.burger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
  /*for IE*/
}

.burger:focus {
  outline: none;
}

/* Scroolbars */

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.map-marker {
  margin-top: -30px;
}

.markercss {
  margin-top: -40px;
}